<template>
  <div>
    <h2>
      <span class="h2-sm" v-text="$route.meta.upper_menu + ' / '"></span><span v-text="$route.meta.menu"></span>
      <div class="fright fs16 mr30 normal">
        <!-- companyOrAgentType: 선택 계정 관련 구분값 -->
		  <span class="tx_rt ml10 mr10 bold" style="color:#C91624;" v-if="role == 'CLIENT_MANAGER'">
			  <i class="fas fa-user-cog mr5" style="color:#C91624;"></i>관리자
		  </span>

        <span v-if="companyOrAgentType == 'AGENT'">
          <label class="tx_rt ml20 mr10 bold"><i class="fas fa-caret-right fs14 mr5"></i>아이디</label>
          {{ $attrs.userInfo.CLIENT_MEMBER_ID }}
          <label class="tx_rt ml20 mr10 bold"><i class="fas fa-caret-right fs14 mr5"></i>세무대리인명</label>
          {{ $attrs.userInfo.TAX_AGENT_NAME }}
        </span>
        <span v-else>
          <label class="tx_rt ml20 mr10 bold"><i class="fas fa-caret-right fs14 mr5"></i>아이디</label>
          {{ $attrs.userInfo.CLIENT_MEMBER_ID }}
          <label class="tx_rt ml20 mr10 bold"><i class="fas fa-caret-right fs14 mr5"></i>사업장</label>
          {{ $attrs.userInfo.CLIENT_MEMBER_COMPANY_NAME }}
        </span>
        <!--		  user agent 체크 parent한테 받으니까 별도로 체크 필요없을듯?-->
        <button class="btn btn-pr pr10 pl10 ml10 mr5 txt-white" style="background-color:#414960;"
                @click="openPopup()" v-if="role == 'USER' || role == 'CLIENT_MANAGER'">사업장 변경
<!--			<i class="fas fa-sync ml10" style="color:white"></i>-->
		</button>
      </div>
    </h2>
    <!--	  사업장 변경 팝업-->
    <base-user-company-change-popup
        :isVisible="isVisiblePopup"
        :role="role"
        @toParent:isVisiblePopup="isVisiblePopup = false"
        @toParent:isUpdateCompany="val => isUpdateCompany = val"/>
  </div>
</template>

<script>

export default {
  	name: "CompAppBar",

  props: {
    role: {
      type: String,
    },
    companyOrAgentType: {
      type: String,
      // required: true
    }
  },
	data: () => ({
		//테스트
		isVisiblePopup: false,

    	isUpdateCompany: false,
	}),
	methods: {
  		openPopup() {
			if (this.isVisiblePopup) {

				this.isVisiblePopup = false;
			} else {

				this.isVisiblePopup = true;
			}
		},
	},
  watch: {
    isUpdateCompany(val) {
      if (val) {

        this.$router.go();
      }
    }
  },
}
</script>

<style scoped>

</style>